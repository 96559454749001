import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../layouts/Layout";
import * as PR from "../../../prime-react/index";
import { getNewsDetails, getFileDetails } from '../../../services/api';
import MostViewed from "../../../shared/MostViewed/MostViewed";
import TopCategories from "../../../shared/TopCategories/TopCategories";
import { DateFormat, extractKeywords } from "../../../utils/reuse";
import dbData from "../../../assets/data/db.json"
import {
  slide1,
  slide2,
  news1,
  news2,
  news3,
  news4,
  news5,
  news6,
  news7,
  newView1,
  newView2,
  Avatar,
  newView3,
  newView4,
  newView5,
  newView6,
} from "../../../assets/images/index";
const SportingSanctionDetail = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const [newsDetail, setNewsDetail] = useState(null);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [fileUrls, setFileUrls] = useState([]);

  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionData = useSelector(state => state.auth.sessionData);
  const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
  const imageMap = {
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
    slide1,
    slide2,
    Avatar
  };
useEffect(() => {
  setMostViewedData(dbData.mostViewed);
}, []);
  useEffect(() => {
    if (id && headers) {
      getNewsDetails(id, headers, dispatch, (response) => {
        if (response.result === 'SUCCESS') {
          const responseData = response.data;
          const newsData = responseData?.map(news => ({
            ...news,
            fileAttachments: news?.fileAttachments ? JSON.parse(news.fileAttachments) : []
          }));
          setNewsDetail(newsData[0]);
        } else {
          const error = response?.error;
          toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
        }
        setLoading(false);
      });
    }
  }, [id, headers, dispatch]);

  useEffect(() => {
    if (newsDetail?.fileAttachments?.length > 0) {
      const attachments = newsDetail.fileAttachments;

      attachments.forEach(attachment => {
        getFileDetails(attachment.key, headers, dispatch, response => {
          if (response.result === 'SUCCESS') {
            const url = URL.createObjectURL(response.data);
            if (attachment.type === "image/jpeg" || attachment.type === "image/png") {
              setAttachmentImage(url);
            } else if (['pdf', 'xls', 'xlsx', 'docx', 'doc'].includes(attachment.name.split('.').pop())) {
              setFileUrls(prev => [...prev, { url, name: attachment.name, key: attachment.key }]);
            }
          } else {
            const error = response?.error;
            toast.current?.show({ severity: error?.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
          }
        });
      });
    }
  }, [newsDetail?.fileAttachments, headers, dispatch]);

  const handleDownload = (url, name) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(err => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to download file' });
      });
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
    navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
  };


  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }

  if (!newsDetail) {
    return (
      <div className="no-data">
        <p>No news details available.</p>
      </div>
    );
  }

  const { title, description,keywords, createdAt } = newsDetail;
  const { warning, fine } = extractKeywords(keywords);
  return (
    <Layout>
      <div className="back-button">
        <Link to="/sporting-sanction">
          <i className="pi pi-arrow-left"></i>
          Back to sanctions
        </Link>
      </div>
      <section className="news-tab news-details-section">
        <div className="grid align-items-center justify-content-start">
          <div className="col-9">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="grid">
          <div className="col-9">
            <div className="new-list-section">
              <div className="list-wrapper">
                <div className="news-list">
                  <div className="card">
                    <div className="col-12 p-0">
                      <div className="flex align-items-center justify-content-between">
                        <ul className="flex list-none align-items-center gap-4">
                        {warning && (
                                        <li>
                                          <i className="pi pi-exclamation-triangle"></i>{" "}
                                          <span className="warning">{warning}</span>
                                        </li>
                                      )}
                                      {fine && (
                                        <li>
                                          <i className="pi pi-money-bill"></i>{" "}
                                          <span className="fine">{fine}</span>
                                        </li>
                                      )}
                        </ul>
                        <ul className="flex list-none align-items-center gap-4">
                          <li>
                            <i className="pi pi-calendar"></i>{" "}
                            <span>{DateFormat(createdAt)}</span>
                          </li>
                        </ul>
                      </div>
                      {attachmentImage && (
                        <PR.Image
                          src={attachmentImage}
                          alt={title}
                          className="news-img"
                        />
                      )}
                    </div>
                    <div className="col-12 p-0">
                      <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                      {fileUrls && fileUrls?.length > 0 && (
                        <div className='attachments'>
                          <span className='heading-span'>Attached Files:</span>
                          {fileUrls?.map((file, index) => (
                            <PR.Button
                              key={file.key}
                              icon="pi pi-book"
                              label={file.name}
                              className="download-button hide-on-download"
                              onClick={() => handleDownload(file.url, file.name)}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <MostViewed mostViewedData={mostViewedData} imageMap={imageMap}/>
            <TopCategories
              heading={"Top news categories"}
              handleCategoryClick={handleCategoryClick}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SportingSanctionDetail;
