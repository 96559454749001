import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsCategories } from "../../services/api";
import { categoryColors } from "../../utils/reuse";
import "./TopCategories.scss";

const TopCategories = ({ handleCategoryClick, heading }) => {
  const [newsCategory, setNewsCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
    };
  }, [sessionData.sessionId]);

  useEffect(() => {
    setIsLoading(true);
    getNewsCategories(headers, dispatch, (response) => {
      setIsLoading(false);
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setNewsCategory(result);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.error.errorMsg,
        });
      }
    }, true, false);
  }, [headers, dispatch]);

  return (
 newsCategory?.length > 0 && 
  <div className="categories-section">
      <div className="header-section">
        <h3>
          <i className="pi pi-th-large"></i> {heading}
        </h3>
      </div>
      {isLoading ? (
        <div className="loading">
          <i className="pi pi-spin pi-spinner"></i>
        </div>
      ) : (
        <div className="categories-list">
          {newsCategory?.map((category, index) => (
            <ul
              key={index}
              onClick={() => handleCategoryClick(category?.id, category?.name)}
              className="flex list-none align-items-center justify-content-between cursor-pointer"
            >
              <li>
                <span className={`dot ${categoryColors[category?.name]}`}></span> {category?.name}
              </li>
              <li>{category?.newsUpdatesCount >= 0 ? category?.newsUpdatesCount : 0} items</li>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopCategories;
