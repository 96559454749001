import React, { useState } from 'react';

// **** Main CSS **** //
import '../src/assets/scss/main.scss'
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './auth/Login/Login';
import ForgotPassword from './auth/ForgotPassword/ForgotPassword';
import CreateAlert from './shared/CreateAlert/CreateAlert';
import AlertDetails from './shared/AlertDetails/AlertDetails';
import { useSelector } from 'react-redux';
import Profile from './shared/Profile/Profile';
import Alerts from './modules/Members/Alerts/Alerts';
import Home from './modules/Members/Home/Home';
import MyAnalytics from './modules/Members/MyAnalytics/MyAnalytics';
import CaseUpdates from './shared/CaseUpdates/CaseUpdates';
import NewsLayout from './shared/News/NewsLayout';
import ExternalLinks from './shared/ExternalLinks/ExternalLinks';
import NewsDetails from './shared/News/NewsDetails';
import Help from './pages/Help/Help';
// import Leagues from './shared/Leagues/Leagues';
import MyAnalyticsDetails from './modules/Members/MyAnalytics/MyAnalyticsDetails';
import AnalystHome from './modules/Analyst/Home/Home';
import AlertsTemplate from './modules/Analyst/Home/AlertsTemplate';
import AlertTags from './modules/Analyst/AlertTags/AlertTags';
import UserManagement from './modules/Analyst/UserManagement/UserManagement';
import AllUsers from './modules/Analyst/UserManagement/AllUsers/AllUsers';
import NewsAndUpdates from './modules/Analyst/NewsAndUpdates/NewsAndUpdates';
import GeneralNewsDetails from './modules/Analyst/NewsAndUpdates/GeneralNewsDetails/GeneralNewsDetails';
import EditDetails from './modules/Analyst/NewsAndUpdates/GeneralNewsDetails/EditDetails';
import PreviewNews from './modules/Analyst/NewsAndUpdates/GeneralNewsDetails/PreviewNews';
import CreateNews from './modules/Analyst/NewsAndUpdates/GeneralNewsDetails/CreateNews';
import NewsCategories from './modules/Analyst/NewsAndUpdates/NewsCategories/NewsCategories';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Reports from './shared/Reports/Reports';
import CreateReport from './modules/Analyst/Reports/CreateReport/CreateReport';
import PreviewReport from './modules/Analyst/Reports/PreviewReport/PreviewReport';
import DownloadReport from './modules/Analyst/Reports/DownloadReport/DownloadReport';
import RegulatorHome from './modules/Regulator/Home/Home';
import RegulatorAlerts from './modules/Regulator/Alerts/Alerts';
import SportingSanction from './modules/Members/SportingSanction/SportingSanction';
import SportingSanctionDetail from './modules/Members/SportingSanctionDeatils/SportingSanctionDetails';
import WeeklyIntegrityPreview from './modules/Members/WeeklyIntegrityPreview/WeeklyIntegrityPreview';
import NewsCategory from './shared/NewsCategory/NewsCategory';
import EditReport from './modules/Analyst/Reports/Edit Reports/EditReports';
import ReportDetail from './shared/Reports/ReportDetail';
import AnalystAnalyticsDetails from './modules/Analyst/MyAnalytics/AnalyticsDetails';
import AnalystAnalytics from './modules/Analyst/MyAnalytics/Analytics';
import SetPassword from './auth/SetPassword';

function App() {

  const [activeIndex, setActiveIndex ] = useState(0)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isMember = useSelector(state => state.auth.role) === 'member';
  const isRegulator = useSelector(state => state.auth.role) === 'regulator';
  const isAnalyst = useSelector(state => state.auth.role) === 'analyst';

  const handleTabChange = (index) => {
    setActiveIndex(index)
  }

  return (
    <Router>
      { !isMember && !isRegulator && !isAnalyst && (
        <Routes>
          <Route path='/' element={<Navigate to='/login' />} />
          <Route path='/login' element={isLoggedIn ? <Navigate to='/home' /> : <Login />} />
          <Route path='/forgot-password' element={isLoggedIn ? <Navigate to='/home' /> : <ForgotPassword />} />
          <Route path='/set-password' element={isLoggedIn ? <Navigate to='/home' /> : <SetPassword />} />
          <Route path='/reset-password' element={isLoggedIn ? <Navigate to='/home' /> : <SetPassword />} />
          <Route path='/page-not-found' element={isLoggedIn ? <PageNotFound /> : <Navigate to='/' />} />
          <Route path='*' element={isLoggedIn ? <Navigate to='/page-not-found' /> : <Navigate to='/' />} />
        </Routes>
      )}
     
      {isMember && (<Routes>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={isLoggedIn ? <Navigate to='/home' /> : <Login />} />
        <Route path='/home' element={isLoggedIn ? <Home handleTabChange={handleTabChange} /> : <Navigate to='/' />} />
        <Route path='/forgot-password' element={isLoggedIn ? <Navigate to='/home' /> : <ForgotPassword />} />
        <Route path='/alerts' element={isLoggedIn ? <Alerts setActiveIndex={setActiveIndex} activeIndex={activeIndex} /> : <Navigate to='/' />} />
        <Route path='/create-alert' element={isLoggedIn ? <CreateAlert /> : <Navigate to='/' />} />
        <Route path='/alert-details/:id' element={isLoggedIn ? <AlertDetails /> : <Navigate to='/' />} />
        <Route path='/my-analytics' element={isLoggedIn ? <MyAnalytics /> : <Navigate to='/' />} />
        <Route path='/my-analytics-details' element={isLoggedIn ? <MyAnalyticsDetails /> : <Navigate to='/' />} />
        <Route path='/case-updates' element={isLoggedIn ? <CaseUpdates /> : <Navigate to='/' />} />
        <Route path='/news' element={isLoggedIn ? <NewsLayout /> : <Navigate to='/' />} />
        <Route path="/news-category" element={isLoggedIn ? <NewsCategory /> : <Navigate to='/' />} />
        <Route path='/sporting-sanction' element={isLoggedIn ? <SportingSanction /> : <Navigate to='/' />} />
        <Route path="/news-details" element={isLoggedIn ? <NewsDetails /> : <Navigate to="/" />} />
        <Route path="/sanction-details" element={isLoggedIn ? <SportingSanctionDetail /> : <Navigate to="/" />} />
        <Route path="/weekly-integrity" element={isLoggedIn ? <WeeklyIntegrityPreview /> : <Navigate to="/" />} />
        <Route path='/external-links' element={isLoggedIn ? <ExternalLinks /> : <Navigate to='/' />} />
        <Route path='/profile-details' element={isLoggedIn ? <Profile /> : <Navigate to='/' />} />
        {/* <Route path='/leagues' element={isLoggedIn ? <Leagues /> : <Navigate to='/' />} /> */}
        <Route path='/help' element={isLoggedIn ? <Help /> : <Navigate to='/' />} />
        <Route path='/analyst-home' element={isLoggedIn ? <AnalystHome /> : <Navigate to='/' />} />
        <Route path='/page-not-found' element={isLoggedIn ? <PageNotFound /> : <Navigate to='/' />} />
        <Route path='*' element={isLoggedIn ? <Navigate to='/page-not-found' /> : <Navigate to='/' />} />
      </Routes>
      )}

      {isAnalyst && (<>
        <Routes>
          <Route path='/' element={<Navigate to='/login' />} />
          <Route path='/login' element={isLoggedIn ? <Navigate to='/home' /> : <Login />} />
          <Route path='/home' element={isLoggedIn ? <AnalystHome /> : <Navigate to='/' />} />
          <Route path='/login' element={isLoggedIn ? <Navigate to='/' /> : <Login />} />
          <Route path='/alert-details/:id' element={isLoggedIn ? <AlertDetails /> : <Navigate to='/' />} />
          <Route path='/open-alerts'
            element={isLoggedIn 
              ? <AlertsTemplate
                  alertStatus="open"
                  title="Open Alerts"
                />
              : <Navigate to='/' />}
          />
          <Route path='/evaluating-alerts' 
            element={isLoggedIn 
              ? <AlertsTemplate
                  alertStatus="evaluating"
                  title="Evaluating Alerts"
                /> 
              : <Navigate to='/' />} 
          />
          <Route path='/suspicious-alerts' 
            element={isLoggedIn 
              ? <AlertsTemplate
                  alertStatus=""
                  title="Suspicious Alerts"
                /> 
              : <Navigate to='/' />} 
          />

          <Route path='/my-alerts'
            element={isLoggedIn 
              ? <AlertsTemplate
                  alertStatus=""
                  title="My alerts"
                /> 
              : <Navigate to='/' />} 
          />
          <Route path='/create-alert' element={isLoggedIn ? <CreateAlert /> : <Navigate to='/' />} />
          <Route path='/create-news' element={isLoggedIn ? <CreateNews /> : <Navigate to='/' />} />
          <Route path='/news-categories' element={isLoggedIn ? <NewsCategories /> : <Navigate to='/' />} />
          <Route path="/news-detail" element={<GeneralNewsDetails />} />
          <Route path="/preview-news" element={<PreviewNews />} />
          <Route path="/edit-details" element={<EditDetails />} />
          <Route path='/news' element={isLoggedIn ? <NewsAndUpdates /> : <Navigate to='/' />} />
          <Route path='/case-updates' element={isLoggedIn ? <CaseUpdates /> : <Navigate to='/' />} />
          <Route path='/my-analytics' element={isLoggedIn ? <AnalystAnalytics /> : <Navigate to='/' />} />
          <Route path='/my-analytics-details' element={isLoggedIn ? <AnalystAnalyticsDetails /> : <Navigate to='/' />} />
          <Route path='/tags' element={isLoggedIn ? <AlertTags /> : <Navigate to='/' />} />
          <Route path='/create-alert' element={isLoggedIn ? <CreateAlert /> : <Navigate to='/' />} />
          <Route path='/alerts' 
            element={isLoggedIn 
              ? <AlertsTemplate 
                  alertStatus=""
                />
              : <Navigate to='/' />} 
          />
          <Route path='/user-management' element={isLoggedIn ? <UserManagement /> : <Navigate to='/' />} />
          <Route path='/all-users' element={isLoggedIn ? <AllUsers /> : <Navigate to='/' />} />
          <Route path='/reports' element={isLoggedIn ? <Reports /> : <Navigate to='/' />} />
          <Route path='/create-report' element={isLoggedIn ? <CreateReport /> : <Navigate to='/' />} />
          <Route path='/report-detail' element={isLoggedIn ? <ReportDetail /> : <Navigate to='/' />} />
          <Route path='/preview-report' element={isLoggedIn ? <PreviewReport /> : <Navigate to='/' />} />
          <Route path='/download-report' element={isLoggedIn ? <DownloadReport /> : <Navigate to='/' />} />
          <Route path='/edit-report' element={isLoggedIn ? <EditReport /> : <Navigate to='/' />} />
          <Route path='/page-not-found' element={isLoggedIn ? <PageNotFound /> : <Navigate to='/' />} />
          <Route path='*' element={isLoggedIn ? <Navigate to='/page-not-found' /> : <Navigate to='/' />} />
          <Route path='/profile-details' element={isLoggedIn ? <Profile /> : <Navigate to='/' />} />
          <Route path='/help' element={isLoggedIn ? <Help /> : <Navigate to='/' />} />
        </Routes>
      </>)
      }

      {isRegulator && (<>
        <Routes>
          <Route path='/' element={<Navigate to='/login'/>} />
          <Route path='/login' element={isLoggedIn ? <Navigate to='/home' /> : <Login />} />
          <Route path='/home' element={isLoggedIn ? <RegulatorHome handleTabChange={handleTabChange} /> : <Navigate to='/' />} />
          <Route path='/alerts' element={isLoggedIn ? <RegulatorAlerts setActiveIndex={setActiveIndex} activeIndex={activeIndex} /> : <Navigate to='/' />} />
          <Route path='/news' element={isLoggedIn ? <NewsLayout /> : <Navigate to='/' />} />
          <Route path='/external-links' element={isLoggedIn ? <ExternalLinks /> : <Navigate to='/' />} />
          <Route path="/news-category" element={isLoggedIn ? <NewsCategory /> : <Navigate to='/' />} />
          <Route path="/news-details" element={isLoggedIn ? <NewsDetails /> : <Navigate to="/" />} />
          <Route path='/help' element={isLoggedIn ? <Help /> : <Navigate to='/' />} />
          <Route path='/profile-details' element={isLoggedIn ? <Profile /> : <Navigate to='/' />} />
          <Route path='/reports' element={isLoggedIn ? <Reports /> : <Navigate to='/' />} />
          <Route path='/report-detail' element={isLoggedIn ? <ReportDetail /> : <Navigate to='/' />} />
          <Route path='/news' element={isLoggedIn ? <NewsAndUpdates /> : <Navigate to='/' />} />
          <Route path='/preview-report' element={isLoggedIn ? <PreviewReport /> : <Navigate to='/' />} />
          <Route path='/download-report' element={isLoggedIn ? <DownloadReport /> : <Navigate to='/' />} />
          <Route path='/page-not-found' element={isLoggedIn ? <PageNotFound /> : <Navigate to='/' />} />
          <Route path='*' element={isLoggedIn ? <Navigate to='/page-not-found' /> : <Navigate to='/' />} />
        </Routes>
      </>)
      }
    </Router>
  );
}
export default App;
