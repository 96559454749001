import moment from "moment";
import sportIcons from '../assets/images/sports'
import * as PR from "../prime-react/index";
import { eventIcon } from "../assets/images/index";
import { newsTypes, RAW_FILTER_DATA } from "../shared/common";

export const pagecount = 20;
export const chatCount = 500;
export const descriptionCount = 3000;
export const displayStatesForSelectedCountries = ["United States of America (the)", "Canada"]
export const privacyPolicyLink = '/policies/IBIA_Privacy_Notice.pdf';
export const termsAndConditionsLink = '/policies/IBIA_Terms_and_Conditions.pdf'

export const fileUploadLimit = {
  label: "1 MB",
  value: 1048576,
}
export const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const getQueryString = (obj) => {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
};

export const formatDate = (dateString) => {
  const currentDate = new Date(dateString);

  // Extract components
  const optionsDate = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };
  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "UTC",
  };

  const formattedDate = currentDate.toLocaleDateString("en-US", optionsDate);
  const formattedTime = currentDate.toLocaleTimeString("en-US", optionsTime);

  const finalDate = {
    displayDate: formattedDate,
    displayTime: `${formattedTime} UTC`,
  };

  return finalDate;
};

export const formatTime = (date) => {
  const currentDate = new Date(date);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  return <strong style={{fontWeight:"inherit"}} title={moment(date).utc().format("MMMM DD, YYYY HH:mm [UTC]")} >{currentDate
    .toLocaleTimeString("en-US", options)
    .replace(/\bAM\b/, "am")
    .replace(/\bPM\b/, "pm")}</strong>
};

export const DateFormat = (dateString) => {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return <strong style={{fontWeight:"inherit"}} title={moment(dateString).utc().format("MMMM DD, YYYY HH:mm [UTC]")} >{new Date(dateString)
    .toLocaleDateString("en-GB", options)
    .replace(/ /g, " ")}</strong>
};

export const dateTimeFormat = payload => <div title={moment(payload).utc().format("MMMM DD, YYYY HH:mm [UTC]")}>{DateFormat(payload)}, {formatTime(payload)}</div>

export const formatShortDate = (dateString) => {
  const date = moment(dateString);

  const formattedDate = date.format("DD MMM YYYY");

  return formattedDate;
};

export const formatDateRange = (dateString) => {
  if (dateString === "" || dateString === undefined) {
    return undefined;
  }
  const date = moment(dateString);

  // Format the date
  const formattedDate = date.format("YYYY-MM-DD");

  return formattedDate;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getRoleId = (role) => {
  switch (role) {
    case "member":
      return 2;
    case "analyst":
      return 2;
    case "regulator":
      return 3;
    case "governingbody":
      return 4;
    default:
      return null;
  }
};

export const categoryColors = {
  "League Updates ": "red-color",
  "Regulatory Changes ": "green-color",
  "Industry Insights ": "blue-color",
  "Event Previews ": "purple-color",
  "Policy Reminders ": "yellow-color",
  "Educational Resources ": "green-color",
};
export const typeFilter = [
  { id: "1", name: "suspicious betting" },
  { id: "2", name: "summary" },
  { id: "3", name: "others" },
];

export const newsTitleChar = 95;

export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.slice(0, maxLength) + "...";
  }
  return text;
};

export const extractKeywords = (keywords) => {
  const keywordArray = keywords?.split(",");
  const warning = keywordArray?.find((keyword) =>
    isNaN(keyword.trim().charAt(0))
  );
  const fine = keywordArray?.find((keyword) => keyword.includes("$"));
  return { warning, fine };
};

export const capitalizeFirstLetterOfEachSentence = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const getSportIcon = (sport) => {
  if (sportIcons[sport]) {
    const SportIcon = sportIcons[sport]
    return (SportIcon)
  } else {
    return (<PR.Image src={eventIcon} alt="Sport" />)
  }
}

export const getNewsTypeLabel = (newsType) => {
  return newsTypes?.filter(type => type.id === newsType)[0]?.name;
}

export const getStatusLabel = (status) => {
  return RAW_FILTER_DATA.caseStatus?.filter(type => type.id === status)[0]?.name;
}

export const sortAlerts = (alerts, groupName) => {
  return alerts?.sort((a, b) => {
    // Define sorting priorities
    const priority = (alert) => [
      (alert.alertStatus === "open" && alert?.responseRequired) ? 0 : 1, // 0 if responseRequired, 1 otherwise
      (alert.alertStatus === "evaluating" && alert?.responseRequired) ? 0 : 1,
      (alert?.activeConversation) ? 0 : 1, // 0 if activeConversation, 1 otherwise
      alert?.alertStatus === "open" ? 0 : (alert?.alertStatus === "evaluating" ? 1 : 2), // 0 if "open", 1 if "evaluating", 2 otherwise
      alert?.createdByGroupName !== groupName ? 0 : 1, // Other each section other group alerts comes first, and the own group alerts at the end
      -new Date(alert?.createdAt) // Date for final sorting
    ];

    // Compare based on the defined priorities
    const aPriority = priority(a);
    const bPriority = priority(b);

    return aPriority
    ?.map((val, index) => val - bPriority[index])
    ?.find(diff => diff !== 0) || 0; // find the first non-zero difference or return 0 if all are equal
  });
};

export const optionsforBarGraphs = {
  plugins: { 
      legend: false,
  },
  scales: {
      x: {
          grid: {
              display: false
          }
      },
      y: {
          grid: {
              color: '#D1DCD3',
          },
          border: {
              dash: [5, 6],
          }
      }
  }
};

export const optionsforBarGraphsWithPercentage = {
  plugins: { 
      legend: false,
  },
  scales: {
      x: {
          grid: {
              display: false
          }
      },
      y: {
          grid: {
              color: '#D1DCD3',
          },
          border: {
              dash: [5, 6],
          },
          ticks: {
              callback: function(value) {
                  return `${value} %`;
              }
          }
      }
  }
};


export const optionsforResponseType = {
  plugins: { legend: false },
  indexAxis: 'y',
  aspectRatio: 0.8,
  responsive: true,
  maintainAspectRatio: false,
  scales: {
      x: {
          stacked: true,
          ticks: { display: false },
          grid: { display: false },
          border: { display: false }
      },
      y: {
          stacked: true,
          ticks: { display: false },
          grid: { display: false },
          border: { display: false }
      }
  }
};
export const textTypingHandler = (e, setValue, limit) => {
  let value = e.target.value;
  if (value.length <= limit) {
    setValue(value);
  }
};

export const handlePaste = (e, value, setValue, limit) => {
  e.preventDefault();
  const pasteText = e.clipboardData.getData('text');
  const newText = value + pasteText;
  if (newText.length <= limit) {
    setValue(newText);
  } else {
    setValue(newText.substring(0, limit));
  }
};

// Function to captilize the first letter 
export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const permission ={
news: 3,
reports:4

}

export const roleIds ={
  regulator: 3,
  governingbody:4
  
  }

// Function to remove null values and empty arrays
export const cleanData = (data) => {
  if (Array.isArray(data)) {
    const cleanedArray = data
      .map(cleanData)
      .filter(item => 
        item !== null && 
        item !== undefined && 
        !(typeof item === 'object' && !Array.isArray(item) && Object.keys(item).length === 0) && // Remove empty objects
        !(Array.isArray(item) && item.length === 0) // Remove empty arrays
      );

    // Return null for empty arrays
    return cleanedArray.length > 0 ? cleanedArray : null;
  }

  if (typeof data === 'object' && data !== null) {
    const cleanedObject = Object.entries(data)
      .reduce((acc, [key, value]) => {
        const cleanedValue = cleanData(value);
        if (cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== '') {
          acc[key] = cleanedValue;
        }
        return acc;
      }, {});

    // Return null for empty objects
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
  }

  // Remove null, undefined, and empty strings
  return data !== null && data !== undefined && data !== '' ? data : null;
};

export const handlePolicyDownload = (event, url, filename) => {
  event.preventDefault();
  const link = document.createElement('a');
  link.href = url; // The path to the PDF
  link.download = filename; // Set the filename for download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up
};

export const sortByCreatedAt = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  return data.sort((a, b) => {
    const dateDifference = new Date(b.createdAt) - new Date(a.createdAt);
    
    if (dateDifference !== 0) {
      return dateDifference; // Sort by createdAt first
    }

    // Handle either `id` or `reportId`
    const idA = a.id || a.reportID;
    const idB = b.id || b.reportID;

    if (idA && idB) {
      return idA - idB; // Assuming ids are strings
    }

    return 0; // If neither id nor reportId exist, consider them equal
  });
}
