import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import "./EditReport.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSports,
  getReportById,
  getFileDetails,
  getGroupsWithPermission,
} from "../../../../services/api";
import secureLocalStorage from "react-secure-storage";
import { fileUploadLimit, permission,roleIds, typeFilter } from "../../../../utils/reuse";

const EditReport = () => {
  const [initialRegulators, setInitialRegulators] = useState([]);
  const [initialGoverningBodies, setInitialGoverningBodies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const fileUploadRef = useRef(null);
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "multipart/form-data",
    };
  }, [sessionData.sessionId]);

  const [regulators, setRegulators] = useState([]);
  const [governingBodies, setGoverningBodies] = useState([]);
  const [sports, setSports] = useState([]);

  const validationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Report title is required"),
    attachments: Yup.array()
      .max(3, "You can upload up to 3 files")
      .test("fileSize", `Each file must be less than ${fileUploadLimit.label}`, (files) =>
        files.every((file) => file.size <= fileUploadLimit.value)
      ),
    reportDescription: Yup.string().required("Report description is required"),
    reportType: Yup.object().nullable().required("Report type is required"),
    sport: Yup.string().nullable().required("Sport is required"),
    regulators: Yup.array().of(Yup.object().nullable()),
    governingBodies: Yup.array().of(Yup.object().nullable()),
  })
  .test(
    "totalFileLimit",
    "You can upload a maximum of 3 files (including existing and new files)",
    function (values) {
      const totalFiles = values.attachments.length + attachments.length;
      return totalFiles <= 3;
    }
  )
  .test(
    "oneOfRequired",
    "At least one regulator or governing body is required",
    function (values) {
      const { regulators, governingBodies } = values;
      return (
        (regulators && regulators?.length > 0) ||
        (governingBodies && governingBodies?.length > 0)
      );
    }
  );


  const [initialValues, setInitialValues] = useState({
    title: "",
    attachments: [],
    reportDescription: "",
    reportType: null,
    sport: null,
    regulators: [],
    governingBodies: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize:true,
    onSubmit: (values) => {
      const totalFiles = values.attachments.length + attachments.length;
      if (totalFiles > 3) {
        formik.setErrors({
          ...formik.errors,
          attachments: "You can upload a maximum of 3 files (including existing and new files)",
        });
        return;
      }
      if (
        values.regulators.length === 0 &&
        values.governingBodies.length === 0
      ) {
        formik.setErrors({
          ...formik.errors,
          oneOfRequired: "At least one regulator or governing body is required",
        });
        return;
      }
      const newRegulators = values.regulators.filter(
        (reg) => !initialRegulators.some((initial) => initial.id === reg.id)
      );
      const newGoverningBodies = values.governingBodies.filter(
        (gb) => !initialGoverningBodies.some((initial) => initial.id === gb.id)
      );
      const data = {
        title: values.title,
        description: values.reportDescription,
        reportID: parseInt(id),
        reportType: values.reportType.name.toLowerCase(),
        sportID: values.sport,
        deletedAttachments, 
      };

      if (newRegulators.length > 0 || newGoverningBodies.length > 0) {
        data.receivers = [
          ...newRegulators?.map((reg) => reg.id),
          ...newGoverningBodies?.map((gb) => gb.id),
        ];
      }

      const selectedSport = sports.find(
        (sport) => sport.value === values.sport
      );
      const sportName = selectedSport ? selectedSport.label : "";

      secureLocalStorage.setItem("attachments", values.attachments);
      secureLocalStorage.setItem("reportData", JSON.stringify(data));
      secureLocalStorage.setItem("sportName", sportName);
      navigate("/preview-report");
    },
  });

  const handleFileSelect = (event) => {

    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
    const duplicateFiles = (
      currentAttachments.some(existingFile => existingFile.name === newAttachment.name) ||
      attachments.some(existingFile => existingFile.name === newAttachment.name)
    )  
    if (duplicateFiles) {
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "File names must be unique. Please rename your file(s) before uploading.",
      });
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 + attachments?.length > 3) {
      toast.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "You can upload a maximum of 3 files (including existing and new files).",
      });
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue("attachments", updatedAttachments);
    }
  };
  
  const handleDeleteFile = (index) => {
    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };

  useEffect(() => {
    getReportById(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const reportDetail = responseData.length > 0 && responseData[0];
        const sportId =
          sports?.find((sport) => sport.label === reportDetail.sportName)
            ?.value || "";
        const reportType =
          typeFilter?.find((type) => type.name === reportDetail.reportType) ||
          null;
        const selectedRegulators = reportDetail.receivers
          ?.filter((receiver) => receiver.role === "regulator")
          ?.map((receiver) => ({ id: receiver.id, name: receiver.name }));
        const selectedGoverningBodies = reportDetail.receivers
         ?.filter((receiver) => receiver.role === "governingbody")
          ?.map((receiver) => ({ id: receiver.id, name: receiver.name }));

        setInitialGoverningBodies(selectedGoverningBodies);
        setInitialRegulators(selectedRegulators);
        setInitialValues({
          title: reportDetail.title || "",
          reportDescription: reportDetail.description || "",
          reportType: reportType,
          sport: sportId,
          regulators: selectedRegulators,
          governingBodies: selectedGoverningBodies,
          attachments: [],
        });

        if (reportDetail.fileAttachment) {
          const attachmentsArray = JSON.parse(reportDetail.fileAttachment);
          if (attachmentsArray && attachmentsArray.length > 0) {
            const attachmentPromises = attachmentsArray?.map((attachment) =>
              getFileDetails(attachment.key, headers, dispatch)
            );
            Promise.all(attachmentPromises).then((attachmentResponses) => {
              const attachmentData = attachmentResponses?.map(
                (attachmentResponse, index) => ({
                  name: attachmentsArray?.length > 0 && attachmentsArray[index].name,
                  data: attachmentResponse?.data,
                  key: attachmentsArray?.length > 0 && attachmentsArray[index].key,
                })
              );
              setAttachments(attachmentData);
            });
          }
        }

        setLoading(false);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch report details",
        });
        setLoading(false);
      }
    });
      // eslint-disable-next-line
  }, [id, headers, dispatch, sports]);

  useEffect(() => {
    getGroupsWithPermission(roleIds.regulator, permission?.reports,  headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setRegulators(result);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.error.errorMsg,
        });
      }
    });

    getGroupsWithPermission(roleIds?.governingbody,permission?.reports, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setGoverningBodies(result);
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: response.error.errorMsg,
        });
      }
    });

    getSports(headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const sports = response.data?.map((sport) => ({
          label: sport.name,
          value: sport.id,
        }));
        setSports(sports?.length > 0 ? sports?.sort((a, b) => a.label.localeCompare(b.label)) : []);
        if (sports.length === 0) {
          toast.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: "No records found",
          });
        }
      } else {
        setSports([]);
        const error = response.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg || error.summary,
        });
      }
    });
  }, [dispatch, headers]);

  const disableSelectedOptions = (options, selectedValues) => {
    return options?.map((option) => ({
      ...option,
      disabled: selectedValues.includes(option.value),
    }))?.sort((a, b) => a.label.localeCompare(b.label));
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();

  const downloadAttachment = (fileData, fileName) => {
    const link = document.createElement("a");
    link.href = fileData;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteExistingFile = (index) => {
    const updatedAttachments = [...attachments];
    const deletedAttachment = updatedAttachments.splice(index, 1)[0];
    setAttachments(updatedAttachments);
    setDeletedAttachments([
      ...deletedAttachments,
      {
        type: "application/pdf", 
        key: deletedAttachment.key, 
      },
    ]);
  };

  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }
  return (
    <Layout backBtn={"reports"}>
      <section className="news-tab news-details-section general-news-section">
        <div className="flex align-items-center justify-content-between mb-4">
          <div className="left">
            <h1>Edit Report</h1>
          </div>
          <div className="right">
            <PR.Button
              label="Update & Preview"
              className="action-buttons save-button"
              disabled={!formik.dirty}
              onClick={formik.handleSubmit}
              type="submit"
            />
          </div>
        </div>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="grid">
            <div className="col-12">
              <div className="new-list-section">
                <div className="list-wrapper">
                  <div className="news-list edit-details">
                    <div className="card">
                      <div className="col-12 p-0 mb-4">
                        <div className="flex flex-column form-input gap-2">
                          <label htmlFor="title">
                            Report title <span className="text-red">*</span>
                          </label>
                          <PR.InputText
                            id="title"
                            name="title"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`input-field ${
                              formik.touched.title && formik.errors.title
                                ? "p-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.title && formik.touched.title && (
                            <div className="text-red">
                              {formik.errors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="flex flex-column gap-2">
                          <label htmlFor="attachments">Attach Files</label>
                          <div className="card create-file-upload flex align-items-center">
                            <PR.FileUpload
                              auto
                              mode="advanced"
                              name="attachments"
                              accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              chooseLabel="Choose Files"
                              multiple
                              ref={fileUploadRef}
                              onSelect={handleFileSelect}
                              onFocus={() => formik.setFieldTouched("attachments")}
                              customUpload
                            />
                            <span className="ml-2">
                              (Add up to 3 files less than {fileUploadLimit.label} each)
                            </span>
                          </div>
                          {formik.values.attachments && (
                            <div className="uploaded-file-container flex flex-column gap-2">
                              {formik.values.attachments.map((attachment, index) => (
                                <div className="uploaded-files" key={index}>
                                  {attachment.name}
                                  <i
                                    className="pi pi-trash"
                                    onClick={() => handleDeleteFile(index)}
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.attachments &&
                            (formik.touched.attachments || formik.submitCount > 0) && (
                              <div className="error-message" style={{ color: "red" }}>
                                {Array.isArray(formik.errors.attachments) ? (
                                  Array.from(new Set(formik.errors.attachments))?.map(
                                    (error, index) => (
                                      <div key={index}>{error}</div>
                                    )
                                  )
                                ) : (
                                  <div>{formik.errors.attachments}</div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                      {attachments.length > 0 && (
                        <div className="col-12 p-0 mt-2">
                          <div className="flex align-items-center gap-2">
                            {attachments?.map((attachment, index) => (
                              <div
                                key={index}
                                className="attachment-item flex align-items-center"
                              >
                                <PR.Button
                                  type="button"
                                  icon="pi pi-book"
                                  label={attachment.name}
                                  className="attachment-button"
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.data,
                                      attachment.name
                                    )
                                  }
                                />
                                <i
                                  className="pi pi-trash delete-attachment-icon"
                                  onClick={() => handleDeleteExistingFile(index)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="col-12 p-0 mt-4">
                        <div className="flex flex-column gap-2">
                          <label htmlFor="reportDescription">
                            Report description{" "}
                            <span className="text-red">*</span>
                          </label>
                          <div className="card content-editor">
                            <PR.Editor
                              value={formik.values.reportDescription}
                              name="reportDescription"
                              onTextChange={(e) => {
                                formik.setFieldValue(
                                  "reportDescription",
                                  e.htmlValue || ""
                                );
                              }}
                              headerTemplate={header}
                              style={{ height: "450px" }}
                            />
                          </div>
                          {formik.errors.reportDescription && (
                            <div className="text-red">
                              {formik.errors.reportDescription}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="reportType">
                              Report type
                            </label>
                            <PR.Dropdown
                              value={formik.values.reportType}
                              onChange={(e) =>
                                formik.setFieldValue("reportType", e.value)
                              }
                              options={typeFilter}
                              optionLabel="name"
                              name="reportType"
                              placeholder="Select Report type"
                              className={`w-full ${
                                formik.errors.reportType &&
                                formik.touched.reportType
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                            {formik.errors.reportType &&
                              formik.touched.reportType && (
                                <div className="text-red">
                                  {formik.errors.reportType}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="sport">
                              Sport <span className="text-red">*</span>
                            </label>
                            <PR.Dropdown
                              value={formik.values.sport}
                              onChange={(e) =>
                                formik.setFieldValue("sport", e.value)
                              }
                              filter
                              name="sport"
                              options={sports}
                              optionLabel="label"
                              placeholder="Select sport"
                              className="w-full"
                            />
                            {formik.errors.sport && formik.touched.sport && (
                              <div className="text-red">
                                {formik.errors.sport}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-12 pt-0 pb-0">
                          <label htmlFor="regulators">
                            Publish and Send by email to{" "}
                            <span className="text-red">*</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                              value={formik.values.regulators?.map(
                                (reg) => reg.id
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "regulators",
                                  e.value?.map((id) =>
                                    regulators.find((reg) => reg.id === id)
                                  )
                                );
                              }}
                              options={disableSelectedOptions(
                                regulators?.map((reg) => ({
                                  label: reg.name,
                                  value: reg.id,
                                })),
                                initialRegulators?.map((reg) => reg.id)
                              )}
                              optionLabel="label"
                              filter
                              placeholder="Regulators"
                              maxSelectedLabels={3}
                              className="w-full"
                              pt={{
                                checkboxContainer: {
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                              value={formik.values.governingBodies?.map(
                                (body) => body.id
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "governingBodies",
                                  e.value?.map((id) =>
                                    governingBodies.find(
                                      (body) => body.id === id
                                    )
                                  )
                                );
                              }}
                              options={disableSelectedOptions(
                                governingBodies?.map((body) => ({
                                  label: body.name,
                                  value: body.id,
                                })),
                                initialGoverningBodies?.map((body) => body.id)
                              )}
                              optionLabel="label"
                              filter
                              placeholder="Sport Governing Bodies"
                              maxSelectedLabels={3}
                              className="w-full"
                              pt={{
                                checkboxContainer: {
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    e.target.parentNode.click();
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        {formik.errors.oneOfRequired &&
                          (formik.touched.regulators ||
                            formik.touched.governingBodies) && (
                            <div className="col-12 text-red mt-2">
                              {formik.errors.oneOfRequired}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-4">
            <PR.Button
              label="Update & Preview"
              className="action-buttons save-button"
              disabled={!formik.dirty}
              type="submit"
            />
          </div>
        </form>
      </section>
      <PR.Toast ref={toast} position="top-right" />
    </Layout>
  );
};

export default EditReport;
