import { useRef, useState } from "react";
import * as PR from "../../prime-react/index";
import { getAlertDetails } from "../../services/api";
import { useDispatch } from "react-redux";

const AlertsSearchId = ({headers, goTo}) => {

    const [alertIdSearch,setAlertIdSearch] = useState('');
    const [loading,setLoading] = useState(false);
    const toast = useRef(null);

    const dispatch = useDispatch();
    const searchHandler =  e =>{
        e?.preventDefault()
        if(!alertIdSearch) return;
        setLoading(true);
        getAlertDetails(alertIdSearch, headers, dispatch, ({result, error, data})=>{
            
            if(result==='SUCCESS'&&data?.alertID) goTo(data?.alertID);

            else toast.current?.show({severity: "error", summary: "Alert Not Found"});

            setLoading(false);
            
        })
    }
    return <div className="flex align-items-center">
        <span className="p-input-icon-right">
            <form onSubmit={searchHandler}>
                <PR.InputText
                    disabled={loading}
                    placeholder="Search alert by ID"
                    className="search-box pr-7"
                    value={alertIdSearch}
                    style={{fontSize:13, paddingTop:7, paddingBottom:7}}
                    onChange={({target:{value}}) => { if(Number(value)||value==='') setAlertIdSearch(value);}}
                />
            </form>
            <i>
                {!!alertIdSearch && <i style={{cursor:'pointer'}} onClick={()=>setAlertIdSearch('')} className="pi pi-times mr-2" />}
                {loading
                    ?<i className="pi pi-spin pi-spinner" />
                    :<i style={{cursor:'pointer'}} onClick={searchHandler} className="pi pi-search" />
                }
            </i>
        </span>
        <PR.Toast ref={toast} position="top-right" />
    </div>
}

export default AlertsSearchId;