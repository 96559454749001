import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
    name:'filtes',
    initialState:{},
    reducers:{
        setFilter(state, action){
            return state = action.payload
        },
        resetFilter(state){
            return state = {}
        }
    }
});

export const {setFilter, resetFilter} = filterSlice.actions
export default filterSlice.reducer;