import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { handlePolicyDownload, privacyPolicyLink, termsAndConditionsLink } from '../utils/reuse';

const Footer = () => {
  const [year] = useState(new Date().getFullYear())
  return (
    <>
        <footer>
          <div className='footer-section'>
            <div className="grid align-items-center">
                <div className="col-6">
                  <p>All Rights reserved. &copy;{year} | IBIA</p>
                </div>
                <div className="col-6 text-right">
                    <ul className="flex list-none align-items-center justify-content-end">
                      <li>Developed by <Link to="https://www.bluehouse-technology.com" target='_blank'>Bluehouse Technology</Link></li>
                      <li className="line"> | </li>
                      <li><Link to="#" onClick={(e) => handlePolicyDownload(e, privacyPolicyLink, 'IBIA_Privacy_Notice.pdf')}>Privacy policy</Link></li>
                      <li className="line"> | </li>
                      <li><Link to="#" onClick={(e) => handlePolicyDownload(e, termsAndConditionsLink, 'IBIA_Terms_Conditions.pdf')}>Terms & Conditions</Link></li>
                    </ul>
                </div>
            </div>
          </div>
        </footer>
    </>
  )
}
export default Footer