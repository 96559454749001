import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import "./News.scss";
import Layout from "../../layouts/Layout";
import MenuBar from "../../layouts/MenuBar";
import * as PR from "../../prime-react/index";

import {
    slide1,
    slide2,
    websiteIcon,
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    upcomingNews,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
    Avatar
} from "../../assets/images/index";
import dbData from "../../assets/data/db.json";

import NewsItem from "../../shared/News/NewsItem";
import MostViewed from "../../shared/MostViewed/MostViewed";
import TopCategories from "../../shared/TopCategories/TopCategories";
import { getNews, getFileDetails } from "../../services/api";
import { pagecount, sortByCreatedAt } from "../../utils/reuse";
import moment from "moment";

const NewsLayout = () => {
    const isMember = useSelector(state => state.auth.role) === 'member';
    const toast = useRef(null);
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [images, setImages] = useState([]);
    const [dates, setDates] = useState([]);
    const [filteredNewsData, setFilteredNewsData] = useState([]);
    const [mostViewedData, setMostViewedData] = useState([]);
    const [upcomingIntegrityPreview, setUpcomingIntegrityPreview] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();
    const pageCount = pagecount;

    const imageMap = {
        news1,
        news2,
        news3,
        news4,
        news5,
        news6,
        news7,
        upcomingNews,
        newView1,
        newView2,
        newView3,
        newView4,
        newView5,
        newView6,
        slide1,
        slide2,
    };

    const responsiveOptions = [
        {
            breakpoint: "991px",
            numVisible: 1,
        },
        {
            breakpoint: "767px",
            numVisible: 1,
        },
        {
            breakpoint: "575px",
            numVisible: 1,
        },
    ];

    useEffect(() => {
        setLoading(true);
        setMostViewedData(dbData.mostViewed);
        setUpcomingIntegrityPreview(dbData.upcomingIntegrityPreview);
        getFeaturedNewsList();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if ((searchQuery?.length >= 3 || (dates && dates?.length > 0)) || (searchQuery?.length === 0 && (!dates || dates?.length === 0))) {
            setCurrentPage(1);
            getNewsListHandler(1);
        }
        // eslint-disable-next-line 
    }, [searchQuery, dates]);

    const itemTemplate = (item) => {
        return (
            <img
                src={item?.src}
                alt={item?.alt}
                style={{ width: "100%", display: "block", height: "50%" }}
                onClick={() => navigate(`/news-details?id=${item?.id}`)}
            />
        );
    };

    const caption = (item) => {
        return (
            <>
                {item?.category && <span className="category">{item?.category}</span>}
                <div className="title">{item?.title}</div>
                <p
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: item?.description?.split(" ").slice(0, 15).join(" "),
                    }}
                ></p>
            </>
        );
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            getNewsListHandler(newPage);
        }
    };

    const handleCategoryClick = (categoryId, categoryName) => {
        const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
        navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
    };

    const getFeaturedNewsList = useCallback(() => {
        setLoading(true);
        const obj = {
            offset: 0,
            limit: 5,
            featured: true,
        };

        getNews(obj, headers, dispatch, async (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const imagesPromises = responseData?.map(news => {
                    return new Promise((resolve, reject) => {
                        if (!news.fileAttachments) {
                            return resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            });
                        }
    
                        const attachments = JSON.parse(news.fileAttachments);
                        const validImageAttachments = attachments?.filter(attachment =>
                            ["image/jpeg", "image/jpg", "image/png"].includes(attachment.type)
                        );
    
                        if (validImageAttachments?.length === 0) {
                            return resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            });
                        }
    
                        const imagePromises = validImageAttachments?.map(attachment =>
                            new Promise((resolve, reject) => {
                                getFileDetails(attachment.key, headers, dispatch, fileResponse => {
                                    if (fileResponse.result === 'SUCCESS') {
                                        resolve({
                                            src: URL.createObjectURL(fileResponse.data),
                                            alt: news.title,
                                            category: news.categoryName,
                                            title: news.title,
                                            description: news.description,
                                            id: news.id
                                        });
                                    } else {
                                        reject(new Error('Failed to fetch image'));
                                    }
                                });
                            })
                        );
    
                        Promise.any(imagePromises)
                            .then(image => resolve(image))
                            .catch(() => resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news?.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            }));
                    });
                });
    
                try {
                    const results = await Promise.all(imagesPromises);
                    setImages(results);
                } catch (error) {
                    const errors = response.error;
                    toast.current?.show({
                    severity: errors.severity,
                    summary: 'Error',
                    detail: errors.errorMsg || errors.summary
                });
                } finally {
                    setLoading(false);
                }
            } else {
                const error = response.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary });
                setLoading(false);
            }
        });
    }, [dispatch, headers]);

    const getNewsListHandler = useCallback((page = 1) => {
        setLoading(true);
        const obj = {
            offset: (page - 1) * pageCount,
            limit: pageCount,
            search: searchQuery?.length >= 3 ? searchQuery : undefined,
        };
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }

        getNews(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const totalPages = Math.ceil(responseData?.length / pageCount);
                setFilteredNewsData(responseData);
                setTotalPages(totalPages);
            } else {
                const error = response.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary });
            }
            setLoading(false);
        });
    }, [dispatch, pageCount, headers, searchQuery, dates]);

    return (
        <>
            <Layout>
                <MenuBar />
                <section className="news-tab">
                    <div className="grid">
                        <div className="col-9">
                            <div className="slider-wrapper">
                                {loading ? (
                                    <div className="loading">
                                        <i className="pi pi-spin pi-spinner"></i>
                                    </div>
                                ) : (
                                    images.length > 0 && 
                                    <>
                                    <span className="type">Featured</span>
                                    <PR.Galleria
                                        value={images}
                                        responsiveOptions={responsiveOptions}
                                        numVisible={1}
                                        item={itemTemplate}
                                        caption={caption}
                                        className="news-slider"
                                    />
                                    </>
                                )}
                            </div>

                            {isMember && (
                                <div className="updates-section">
                                    <div className="grid align-items-center">
                                        <div className="col-9">
                                            <div className="flex gap-3 align-items-center">
                                                <div className="icon-wrapper">
                                                    <PR.Image
                                                        src={websiteIcon}
                                                        alt="website Icon"
                                                        className="icon"
                                                    />
                                                </div>
                                                <div>
                                                    <h2>Sporting Sanctions Updates!</h2>
                                                    <p>
                                                        Stay informed and discover sanctions imposed on
                                                        individuals, teams, or organizations for rule
                                                        violations, fair play breaches, and other actions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            <Link to={"/sporting-sanction"}>
                                                <PR.Button
                                                    icon="pi pi-chevron-right"
                                                    label="See all sanctions"
                                                    iconPos="right"
                                                    className="see-all-button"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="hr-line mt-5 mb-5"></div>
                            <div className="new-list-section">
                                <div className="flex align-items-center justify-content-between">
                                    <div className="left">
                                        <h1>News & updates</h1>
                                    </div>
                                    <div className="right flex gap-3">
                                        <div className="flex align-items-center search-bar">
                                            <span className="p-input-icon-left w-12">
                                                <i className="pi pi-search" />
                                                <PR.InputText
                                                    placeholder="Search.."
                                                    className="search-box w-12"
                                                    value={searchQuery}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value?.length <= 15) {
                                                          setSearchQuery(value);
                                                        }
                                                      }}
                                                />
                                            </span>
                                        </div>
                                        <div className="card" style={{ position: 'relative' }}>
                                            <PR.Calendar
                                                value={dates}
                                                onChange={(e) => {
                                                    setDates(e.value);
                                                }}
                                                iconPos="left"
                                                readOnlyInput
                                                showIcon
                                                selectionMode="range"
                                                className="date-calendar"
                                                placeholder="Select Date"
                                                showButtonBar
                                                dateFormat="dd/mm/yy"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="list-wrapper">
                                    {loading ? (
                                        <div className="loading">
                                            <i className="pi pi-spin pi-spinner"></i>
                                        </div>
                                    ) : !filteredNewsData || filteredNewsData?.length === 0 ? (
                                        <div className="flex justify-content-center">
                                            <p className="no-data m-0 text-bluegray-500">
                                                No records found
                                            </p>
                                      </div>
                                    ) : (
                                        filteredNewsData?.map((news) => (
                                            <React.Fragment key={news.id}>
                                                <NewsItem news={news} Avatar={Avatar} newsType="generalnews" />
                                                <div className="hr-line mt-4 mb-4"></div>
                                            </React.Fragment>
                                        ))
                                    )}
                                </div>

                                {/* Pagination Section */}
                                <div className="pagination">
                                    <div className="grid grid-nogutter align-items-center justify-content-between">
                                        <span>Page {currentPage}</span>
                                        <ul className="flex list-none align-items-center gap-3">
                                            <li
                                                className={currentPage === 1 ? "disabled" : ""}
                                                onClick={() => handlePageChange(currentPage - 1)}
                                            >
                                                Previous
                                            </li>
                                            <li
                                                className={(currentPage === totalPages || !filteredNewsData ) ? "disabled" : ""}
                                                onClick={() => handlePageChange(currentPage + 1)}
                                            >
                                                Next
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            {isMember && (
                                <Link to={"/weekly-integrity"}>
                                    <div className="news-right-section">
                                        <div className="header-section">
                                            <h3>
                                                <i className="pi pi-book"></i> Upcoming week's integrity
                                                preview
                                            </h3>
                                        </div>
                                        <div className="upcoming-news">
                                            <PR.Image
                                                src={upcomingNews}
                                                alt="Upcoming Matches"
                                                className="news-img"
                                            />
                                            <h4>Upcoming Matches</h4>
                                            {upcomingIntegrityPreview?.upcomingMatches?.map(
                                                (match, index) => (
                                                    <p key={index}>{match}</p>
                                                )
                                            )}
                                            <div className="hr-line mt-3 mb-3"></div>
                                            <h4>Players' Integrity</h4>
                                            <ul>
                                                {upcomingIntegrityPreview?.playersIntegrity?.map(
                                                    (integrity, index) => (
                                                        <li key={index}>{integrity}</li>
                                                    )
                                                )}
                                            </ul>
                                            <div className="hr-line mt-3 mb-3"></div>
                                            <h4>Match Officials' Conduct</h4>
                                            {upcomingIntegrityPreview?.matchOfficialsConduct?.map(
                                                (conduct, index) => (
                                                    <p key={index}>{conduct}</p>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            )}
                            <TopCategories
                                heading={"Top Categories"}
                                handleCategoryClick={handleCategoryClick}
                            />
                            <MostViewed mostViewedData={mostViewedData} imageMap={imageMap} />
                        </div>
                    </div>
                </section>
            <PR.Toast ref={toast} position='top-right' />
            </Layout>
        </>
    );
};

export default NewsLayout;
