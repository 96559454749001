export const RAW_FILTER_DATA = {
    statusFilter: [
        {"id": "open", "name": "Open"},
        {"id": "evaluating", "name": "Evaluating", "style": "warning"},
        {"id": "suspicious", "name": "Suspicious", "style": "error"},
        {"id": "notreported", "name": "Not Reported", "style": "success"},
        {"id": "not reported", "name": "Not Reported", "style": "success"},
    ],
    caseStatus: [
      {"id": "under investigation", "name": "Under Investigation"},
      {"id": "sanctioned", "name": "Sanctioned"},
      {"id": "closed", "name": "Case Closed"},
      {"id": "no further action", "name": "No Further Action"},
      {"id": "underinvestigation", "name": "Under Investigation"},
      {"id": "nofurtheraction", "name": "No Further Action"},
    ],
    combinedStatus: [
        {"id": "open", "name": "Open"},
        {"id": "evaluating", "name": "Evaluating", "style": "warning"},

        // suspicious-underinvestigation we are not sending suspicious to BE 
        {"id": "-underinvestigation", "name": "Suspicious - Under investigation", "style": "error"},

        // notreported-nofurtheraction we are not sending notreported to BE 
        {"id": "-nofurtheraction", "name": "Not Reported - No Further Action", "style": "success"},

        // suspicious-closed we are not sending suspicious to BE 
        {"id": "-closed", "name": "Suspicious - Case Closed", "style": "error"},

        // suspicious-sanctioned we are not sending suspicious to BE 
        {"id": "-sanctioned", "name": "Suspicious - Sanctioned", "style": "error"},
    ],
    sportsFilter: [
        {"id": "Tennis", "name": "Tennis"},
        {"id": "Football", "name": "Football"},
        {"id": "Badminton", "name": "Badminton"},
        {"id": "Cricket", "name": "Cricket"}
    ],
    alertTypeFilter: [
        {"id": "inside information", "name": "Inside information"},
        {"id": "match manipulation", "name": "Match Manipulation"},
        // {"id": "matchmanipulation", "name": "Match Manipulation"},
        // {"id": "insideinformation", "name": "Inside information"}
    ],
    tournamentFilter: [
        {"id": "English Premier League", "name": "English Premier League"},
        {"id": "National Basketball Association (NBA)", "name": "National Basketball Association (NBA)"},
        {"id": "National Football League", "name": "National Football League"},
        {"id": "ELa Liga (Football)", "name": "La Liga (Football)"},
        {"id": "National Collegiate Athletic Association", "name": "National Collegiate Athletic Association"}
    ],
    stateOfBetsFilter: [
        {"id": "not settled", "name": "Not settled"},
        {"id": "settled and paid", "name": "Settled and paid"},
        {"id": "settled and unpaid", "name": "Settled and unpaid"},
        {"id": "void", "name": "Void"},
        {"id": "frozen", "name": "Frozen"},
    ],
    gender: [
        {"id": "male", "name": "Male"},
        {"id": "female", "name": "Female"},
        {"id": "mixed", "name": "Mixed"}
    ],
    channels: [
        {"id": "online", "name": "Online"},
        {"id": "retail", "name": "Retail"},
    ],
    timeOfBet: [
        {"id": "pre event", "name": "Pre event"},
        {"id": "live", "name": "Live"}
    ],
    confirmOptions: [
        {"id": true, "name": "Yes"},
        {"id": false, "name": "No"}
    ],
    alertsRaised: [
        {"id": "pre event", "name": "Pre event"},
        {"id": "live", "name": "Live"},
        {"id": "post event", "name": "Post event"}
    ],
    customerTypes: [
        {"id": "new", "name": "New"},
        {"id": "regular", "name": "Regular"},
        {"id": "dormant", "name": "Dormant"},
        {"id": "sharp", "name": "Sharp and/or price related (non-suspicious history)"},
        {"id": "wageredpreviously", "name": "Wagered on previously suspicious events"}
    ],
    maxBet: [
        {"id": "yes", "name": "Yes"},
        {"id": "no", "name": "No"},  
    ],
    reportTypeFilter: [
        { id: '1', name: 'Suspicious betting' },
        { id: '2', name: 'Summary' },
        { id: '3', name: 'Other' }
    ],
    receiverFilter: [
        { id: 'regulator_1', name: 'Regulator 1' },
        { id: 'regulator_2', name: 'Regulator 2' },
        { id: 'regulator_3', name: 'Regulator 3' },
        { id: 'regulator_4', name: 'Regulator 4' }
    ],
    responseTypeFilter : [
      { id: 'all', name: 'All' },
      { id: 'offered and suspicious', name: 'Offered and Suspicious', "style": "error" },
      { id: 'offered and not suspicious', name: 'Offered, not Suspicious', "style": "success" },
      { id: 'not offered', name: 'Not Offered' }
    ],
}

export const getDisplayData = (propertyId,propertyKey) => {
    const property = RAW_FILTER_DATA[propertyKey]?.find(property => property.id === propertyId)
    return property?.name
}
export const permissionID = { overview: 1, analytics: 2,  news: 3,  reports: 4, caseupdates: 5, export: 6, }
export const roles = [
    { id: "member", name: "Member" },
    { id: "regulator", name: "Regulator" },
    { id: "governingbody", name: "Sports Governing Body" },
    { id: "managedmember", name: "Managed member" },
  ];

  export const newsTypes = [
    { id: "generalnews", name: "General news" },
    { id: "integritypreviews", name: "Integrity previews" },
    { id: "sportingsanctions", name: "Sporting sanctions" }
  ];
