import { configureStore } from "@reduxjs/toolkit";
import authReducer from './auth'
import newsReducer from './news'
import filter from "./filter.slice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        news: newsReducer,
        filter
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})

export default store;